<template>
    <section class="gardenManagementPage">
        <!-- <g-breadcrumb bread="会员管理" /> -->
        <SearchForm>
            <el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
                <el-form-item>
                    <el-input v-model.trim="searchForm.userName" placeholder="请输入姓名" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryCheckDcOrderPay">搜 索</el-button>

                </el-form-item>
            </el-form>
        </SearchForm>

        <TableContainer title="">

            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="userId" label="员工id" min-width="60" />
                
                <el-table-column prop="dayTime" label="日期" min-width="60" />
                <el-table-column prop="roleName" label="部门" min-width="60" />
                <el-table-column prop="fullName" label="姓名" min-width="60" />
                <el-table-column prop="type" label="类型" min-width="60" />
                <el-table-column prop="overtimeName" label="是否加班" min-width="100" />
                <el-table-column prop="price" label="价格" min-width="60" />
                <el-table-column prop="orderNo" label="订单号" min-width="100" />
                <el-table-column prop="payStateName" label="审核状态" min-width="100" />

                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <span class="textOrange" @click="approveFormClick(scope.row,'2')"
                            v-show="scope.row.payStateName == '待审核'">通过</span>
                        <span class="textOrange" @click="approveFormClick(scope.row,'4')" style="margin-left: 20px;"
                            v-show="scope.row.payStateName == '待审核'">拒绝</span>
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryCheckDcOrderPay" />
        </TableContainer>






    </section>
</template>

<script>
import {
    queryCheckDcOrderPay,
    checkDcOrderOvertime
} from '@api/bx/api_contentManagement';
export default {
    data() {
        return {
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10,
                userName: ""
            },
            total: 0,
            tableData: [],
            typeList: [],
        }
    },
    created() {
        this.queryCheckDcOrderPay()

    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },

        // 列表
        queryCheckDcOrderPay() {
            queryCheckDcOrderPay(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total

            })
        },

        //审核
        approveFormClick(row,id) {
            let text=id=='2'?'通过':'拒绝'
            this.$confirm(row.fullName + '加班报餐是否'+text+'审核?', '提示', {
                confirmButtonText: text+'审核',
                cancelButtonText: '取消',
                type: 'warning'
            }).then((res) => {
                // console.log(res)
                checkDcOrderOvertime({
                    id: row.id,
                    userId: row.userId,
                    dayTime: row.dayTime,
                    type: row.type,
                    payState:id
                }).then(res => {
                    this.$message.success('审核成功')
                    this.queryCheckDcOrderPay()
                })

            })
        },



    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

